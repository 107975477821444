import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23')
];

export const server_loads = [];

export const dictionary = {
		"/": [8],
		"/api": [9,[2]],
		"/archive": [10],
		"/base/[id]": [11,[3]],
		"/base/[id]/newchat": [12,[3]],
		"/base/[id]/settings": [13,[3]],
		"/chat/[id]": [14,[4]],
		"/guest/auth": [15,[5]],
		"/guest/email": [16,[5]],
		"/guest/log-in": [17,[5]],
		"/guest/reset": [18,[5]],
		"/guest/sign-up": [19,[5]],
		"/newchat/[id]": [20,[6]],
		"/public/[id]": [21,[7]],
		"/settings": [22],
		"/welcome": [23]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';